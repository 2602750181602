import React, { PropsWithChildren } from 'react';
import roadImage from '@Assets/images/Login/road.jpeg';
import AppFooter from "@Layouts/AppFooter";

export default function Guest({ children }: PropsWithChildren) {
  return (
    <div className="pages-body login-page flex flex-column relative" style={{ backgroundImage: `url(${roadImage})` }}>
      {children}
      <AppFooter />
    </div>
  );
}
